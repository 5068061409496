import React from 'react';

import Layout from '../layouts/en.jsx';
import { BannerEn, InstagramEn, LatestEn, NewEn } from '@components/pages/home';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

// markup
const IndexPageEn = () => {
  const { wpPage, allWpPost } = useStaticQuery(graphql`
      query HomeEnglish {
        allWpPost(filter: {link: {regex: "/^(/en/|en/)/i"}}) {
          nodes {
            content
            date
            id
            slug
            title
            excerpt
            fieldPosts{
              gallery{
                sourceUrl
                altText
              }
              logo{
                sourceUrl
                altText
              }
              cta{
                text
                url
              }
            }
            featuredImage {
              node{
                sourceUrl
                altText
              }
            }
            language {
              slug
            }
            content
          }
        }
        wpPage(isFrontPage: {eq: true}, link: {regex: "/^(/en/|en/)/i"}) {
          id
          date
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          fieldsHome {
            jumbotron {
              title
              subtitle
              video {
                link
                altText
              }
              bannerImages {
                sourceUrl
              }
            }
            instagram {
              title
              fieldGroupName
              entesdmjc {
                altText
                link
              }
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
              sourceUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
              sourceUrl
            }
            twitterTitle
          }
          slug
          status
        }
      }
  `);
  const { fieldsHome, seo } = wpPage;
  return (
    <>
      <Seo seo={seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/en"/>
      <Layout pageId="home">
        <BannerEn data={fieldsHome.jumbotron} />
        <NewEn data={allWpPost} />
        <InstagramEn data={fieldsHome.instagram}/>
        <LatestEn />
      </Layout>
    </>

  );
};

export default IndexPageEn;
